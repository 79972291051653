import { Button, CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme) => ({
  containerText: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0),
    },
  },
}));
type Textdata = {
  title: string;
  description: string;
  button_href: string;
  button_text: string;
  cta?: CTAData;
};
type Props = {
  data: Textdata;
};

const FeaturedVehicleText: React.FunctionComponent<Props> = ({ data }) => {
  const classes = useStyles();
  const { title, description, button_href, button_text, cta } = data;

  return (
    <Box className={classes.containerText}>
      <h2 className="mobile-t2-semibold md:web-t2-semibold mb-6">
        {parseToHtml(title)}
      </h2>
      <p className="body-16-light mb-6">{parseToHtml(description)}</p>
      {cta ? (
        <CTA data={cta.metadata} />
      ) : (
        <Button label={button_text} href={button_href} />
      )}
    </Box>
  );
};
export default FeaturedVehicleText;
