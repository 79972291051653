import { Container } from '@finn/atoms';
import { FeaturedVehicleCosmicMetadata } from '@finn/ui-cosmic';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import React from 'react';

import FeaturedVehicleImage from './FeaturedVehicleImage';
import FeaturedVehicleText from './FeaturedVehicleText';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',

    display: 'grid',
    gridGap: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(3),
      gridTemplateColumns: '5fr 7fr',
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  data: FeaturedVehicleCosmicMetadata;
};

const FeaturedVehicle: React.FunctionComponent<Props> = ({ data }) => {
  const classes = useStyles();
  const textData = {
    title: data.title,
    description: data.description,
    button_href: data.button_href,
    button_text: data.button_text,
    cta: data.cta,
  };
  const imageData = {
    title: data.title,
    image: data.image,
  };

  return (
    <>
      <Hidden smDown implementation="css">
        <Container>
          <Box className={classes.wrapper}>
            <FeaturedVehicleText data={textData} />
            <FeaturedVehicleImage data={imageData} />
          </Box>
        </Container>
      </Hidden>
      <Hidden mdUp implementation="css">
        <Container>
          <FeaturedVehicleText data={textData} />
        </Container>
        <FeaturedVehicleImage data={imageData} />
      </Hidden>
    </>
  );
};
export default FeaturedVehicle;
