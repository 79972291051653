import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl } from '@finn/ui-utils';
import React from 'react';

import { CosmicImageData } from '~/types/UIModules';

type ImageData = {
  image: CosmicImageData;
  title: string;
};
type Props = {
  data: ImageData;
};

const FeaturedVehicleImage: React.FunctionComponent<Props> = ({ data }) => {
  const { image, title } = data;

  return (
    <div>
      <Image
        src={getCloudinaryImgUrl(image.url, { dpr: 2, w: 960 })}
        className="w-full"
        alt={title}
      />
    </div>
  );
};
export default FeaturedVehicleImage;
